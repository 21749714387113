import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                            <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>You miss 100% of the shots you don't take</p>
                                <br/>
                                <h6>- Wayne Gretzky</h6>
                                <h4>- Michael Scott</h4>
                            </div>
                  
                        </div>
                            </TabPanel>

                            
                            
                            {/* <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                
                            </TabList> */}
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;