import React, { useState, useEffect } from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import Pagination from "./common/Pagination";
import BlogList from "./blog/BlogList";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import PortfolioMasonry from './portfolio/PortfolioMasonry';
import PortfolioGalleryPopUp from './portfolio/PortfolioGalleryPopup';
import PortfolioThree from './portfolio/PortfolioThree';
var api = require('../component/utils/api');




    const FullPortfolio = () => {

   


        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Portfolio'}   />
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        {/* <PortfolioGalleryPopUp /> */}
                        <PortfolioThree/>
                        <div className="row mt--20">
                            <div className="col-lg-12">
                                {/* Start Pagination Area */}
                                {/* <Pagination /> */}
                                {/* End Pagination Area */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    
}
export default FullPortfolio;
