import React, { useState, useEffect } from 'react'
import BlogContent from "../blog/BlogContent";
import {Link} from "react-router-dom";

var api = require('../../component/utils/api');

    const PortfolioThree = () => {

    const [portfolio, setData] = useState({})
    const serverAddress = `https://ajgmultimedia.com/items/portfolio?filter={"status":{"_eq":"published"}}&fields=*,images.directus_files_id,skills.skills_id.*`;

    useEffect(() => {
        // Runs When Component Mounts
       
        api.fetchData(serverAddress).then((cmsData) => {
          setData(cmsData.data)
        })
        console.log(portfolio, 'portfolio items');
      }, [])


      
      if(portfolio.length > 0) {
        return (
             <React.Fragment>
                 <div className="row">
                    {portfolio.map((item ) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={item.id}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href={`/portfolio-details/${item.id}`}>
                                        <img className="w-100" src={`https://ajgmultimedia.com/assets/${item.images[0].directus_files_id}?fit=cover&width=800&height=800&quality=100`} alt="Blog Images"/>
                                    </a>
                                </div>
                                <div className="content">
                                    <p className="blogtype">{item.client}</p>
                                    <h4 className="title text-white">{item.title}</h4>
                                    <div className="blog-btn">
                                    <Link className="rn-btn" to={`/portfolio-details/${item.id}`} >View Project</Link>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                     ))}
                 </div>
             </React.Fragment>
        )
                    }

                    return(
                        <div>
                            
                            </div>
                    )

}
export default PortfolioThree;