import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

class About extends Component{
    render(){

        const SocialShare = [
            {Social: <FaFacebookF /> , link: 'https://www.facebook.com/griffaaron'},
            {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/aaronjgriffiths/'},
            {Social: <FaInstagram /> , link: 'https://www.instagram.com/aaronjgriffiths'},
            {Social: <FaTwitter /> , link: 'https://twitter.com/griffiths_aaron'},
        ]

        
        let title = 'About Me';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/profile.jpg" alt="About Aaron Griffiths"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">I’m an experienced designer and developer located in Northern Utah. My background is a little unconventional as I cover a wide variety of skillsets. I’ll go from developing and soldering up an interactive electronic project / installation to more traditional web development and video / motion graphic design and production. </p> 
                                        <p>I have experience leading design teams as well as planning, estimating and overseeing the completion of larger projects across a variety of mediums.</p>
  
                                    </div>
                                    {/* <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                    </div> */}



<div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-center liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                  



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;