import React, { useState, useEffect, Component } from 'react'
import PageHelmet from '../component/common/Helmet'
import ModalVideo from 'react-modal-video'
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from 'react-icons/fa'
import ScrollToTop from 'react-scroll-up'
import { FiChevronUp } from 'react-icons/fi'
import Header from '../component/header/Header'
import Footer from '../component/footer/Footer'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import parse from 'html-react-parser'
var api = require('../component/utils/api')
const SocialShare = [
  { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
  { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
  { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
  { Social: <FaTwitter />, link: 'https://twitter.com/' },
]

const PortfolioDetails = () => {
  const [portfolio, setData] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const { portfolioid } = useParams()

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  useEffect(() => {
    // Runs When Component Mounts
    const url = `https://ajgmultimedia.com/items/portfolio?filter={"status":{"_eq":"published"},"id":{"_eq":${portfolioid}}}&fields=*,images.directus_files_id,skills.skills_id.*`
    api.fetchData(url).then((cmsData) => {
      setData(cmsData.data)
    })

    console.log('newData', portfolio)
  }, [])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  let title = 'Recent Projects',
    description =
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.'
  if (portfolio.length > 0) {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Portfolio Details" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <motion.div
             initial="hidden"
             animate="visible"
             variants={variants}
           >
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image"
          style={{
            backgroundImage: `url(https://ajgmultimedia.com/assets/${portfolio[0].images[0].directus_files_id}?fit=cover&width=1920&height=600&quality=100)`,
          }}
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                 
               
                    <h2 className="title theme-gradient">
                      {portfolio[0].title}
                    </h2>
             
                  <p>{portfolio[0].client}</p>
                  <br/>
                  <p>
                    {portfolio[0].link != null
                    ? <a className="rn-btn" href={portfolio[0].link} target='_blank'>Visit Website</a>
                    : null
                    }
                    </p>
                   
                </div>
              </div>
            </div>
          </div>
        </div>
        </motion.div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <h2>{portfolio[0].title}</h2>
                    
                    <p className="subtitle"></p>
                
                    <p>{parse(portfolio[0].body)}</p>
                  

                    {/* <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <span>Branch</span>
                        <h4>Ability</h4>
                      </div>

                      <div className="port-view">
                        <span>Project Types</span>
                        <h4>Website</h4>
                      </div>

                      <div className="port-view">
                        <span>Program</span>
                        <h4>View Project</h4>
                      </div>
                    </div> */}
                  </div>
                  <div className="portfolio-thumb-inner">
                    {portfolio[0].video != null ? (
                      <div className="thumb position-relative mb--30">
                        <img
                          src={`https://ajgmultimedia.com/assets/${portfolio[0].video_thumb}?fit=cover&width=1230&quality=100`}
                          alt="Portfolio Images"
                        />
                        <ModalVideo
                          channel="vimeo"
                          isOpen={isOpen}
                          videoId={portfolio[0].video}
                          onClose={() => closeModal()}
                        />
                        <button
                          className="video-popup position-top-center"
                          onClick={() => openModal()}
                        >
                          <span className="play-icon"></span>
                        </button>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {portfolio[0].images.map((value, i) => (
                      <div className="thumb mb--30" key={i}>
                        <img
                          src={`https://ajgmultimedia.com/assets/${portfolio[0].images[i].directus_files_id}?fit=cover&width=1230&quality=100`}
                          alt="Portfolio Images"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }

  return <div></div>
}
export default PortfolioDetails
