import React, { useState, useEffect, Component} from 'react'
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";
var api = require('../../utils/api');

const PortfolioList = [
    {
        image: 'image-1',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: ' Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: ' Getting tickets to the big show'
    }
]

const Portfolio = () => {


        const [portfolio, setData] = useState({})
        const serverAddress = `https://ajgmultimedia.com/items/portfolio?filter={"status":{"_eq":"published"}}&fields=*,images.directus_files_id,skills.skills_id.*`;
    
        useEffect(() => {
            // Runs When Component Mounts
           
            api.fetchData(serverAddress).then((cmsData) => {
              setData(cmsData.data)
            })
            console.log(portfolio, 'portfolio items');
          }, [])

          
        let title = 'Recent Projects',
        description = '';
        if(portfolio.length > 0) {

       
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <Link className="btn-transparent rn-btn-dark" to={'/portfolio'}>View All Projects</Link>
                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {portfolio.map((item) => (
                                <div className="portfolio" key={item.id}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail image-${item.id}`} style={{backgroundImage: `url(https://ajgmultimedia.com/assets/${item.images[0].directus_files_id}?fit=cover&width=800&height=1000&quality=100)`}}></div>
                                        <div className={`bg-blr-image image-${item.id}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{item.client}</p>
                                            <h4 className="title"> <Link to={`/portfolio-details/${item.id}`} >{item.title}</Link></h4>
                                            <div className="portfolio-button">
                                            <Link className="rn-btn" to={`/portfolio-details/${item.id}`} >View Project</Link>
                                                {/* <a className="rn-btn" href="/portfolio-details">Case Study</a> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Link className="link-overlay" to={`/portfolio-details/${item.id}`}></Link> */}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
        return(
            <div></div>
        )
    
}
export default Portfolio;