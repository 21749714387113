import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import ContactModal from '../../elements/common/ContactModal';

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Video Production',
        description: 'Full service video production from concept to production. About Us, Family, Wedding and Commercial production.'
    },
    {
        icon: <FiLayers />,
        title: 'Motion GFX',
        description: 'Animation, Motion GFX can take your videos to the next level. Whatever you have in mind we can find a way to make it happen!'
    },
    {
        icon: <FiUsers />,
        title: 'UI Design',
        description: 'Whether you are working on a website, or an app I can provide you with a design outline to accomplish your objectives.'
    },
    {
        icon: <FiLayers />,
        title: 'Web Development',
        description: 'From CMS powered websites to progressive web apps I can provide an innovatve solution that is crafted to fit your specific needs.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'What I Do',
        description = 'If you have a project in mind and would like to discuss the details feel free to reach out.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                            <ContactModal title="Contact Me"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                               
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                 
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
