import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/griffaaron'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/aaronjgriffiths/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/aaronjgriffiths'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/griffiths_aaron'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row row--0">
                           
                            <div className="col-lg-12 rn-testimonial-area bg_color--5 ptb--30">
                            <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-center liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;