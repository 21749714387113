import React, { useState, useEffect } from 'react'
import ScrollToTop from 'react-scroll-up';
import FooterTwo from "../component/footer/FooterTwo";
import { FiChevronUp} from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import Helmet from "../component/common/Helmet";
var api = require('../component/utils/api');




const CreativePortfolio = () => {

    const [portfolio, setData] = useState({})
    const serverAddress = `https://ajgmultimedia.com/items/portfolio?filter={"status":{"_eq":"published"}}&fields=*,images.directus_files_id,skills.skills_id.*`;

    useEffect(() => {
        // Runs When Component Mounts
    
        api.fetchData(serverAddress).then((cmsData) => {
          setData(cmsData.data)
        })
      }, [])


      if (portfolio.length > 0) {
        console.log(portfolio);
        const listItems = portfolio.map((item) => (

            <div className="col-lg-3 col-md-6 col-12" key={item.id}>
            <div className="portfolio-style--3">
                <div className="thumbnail">
                    <a href="/portfolio-details">
                        <img className="w-100" src={`https://ajgmultimedia.com/assets/${item.images[0].directus_files_id}?fit=cover&width=800&height=1000&quality=100`} alt="Portfolio Images"/>
                    </a>
                </div>
                <div className="content">
                    <p className="portfoliotype">{item.client}</p>
                    <h4 className="title"><a href="/portfolio-details">{item.title}</a></h4>
                    <div className="portfolio-btn">
                        <a className="rn-btn text-white" href="/portfolio-details">Read More</a>
                    </div>
                </div>
            </div>
        </div>

       
           
     
        ))

        return (
            <div>
                 <div className="creative-portfolio">
            <Helmet pageTitle="Creative Portfolio" />
            
            {/* Start Header Area  */}
            {/* <Header headerPosition="header--static" logo="symbol-dark" color="color-black"/> */}
            {/* End Header Area  */}

            {/* Start Portfolio Area  */}
            <div className="creative-portfolio-wrapper bg_color--6">
                <div className="creative-portfolio-wrapper plr--10">
                    <div className="row row--5">
                       
                {listItems}
                    </div>
                </div>
            </div>
            {/* End Portfolio Area  */}            

            {/* Start Footer Style  */}
            {/* <FooterTwo /> */}
            {/* End Footer Style  */}
            
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>

        
            </div>
        
            )

   
        }
    return(
        <div>
            
            </div>
    )
}

export default CreativePortfolio;