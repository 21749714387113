var axios = require('axios');

// var url = 'http://localhost:8055/items/touchtable?';
// var url1 = 'http://137.184.43.199/items/portfolio?filter={"status":{"_eq":"published"},"id":{"_eq":"1"}}&fields=*,images.directus_files_id,skills.skills_id.*';

var key = '';

module.exports = {

  fetchData: function (url) {
    return axios.get(url, { headers: { Authorization: `Bearer ` + key  } })
      .then(function (response) {
        console.log(response.data);
        return response.data;

      });
  }
};